<template>
  <div class="ma-n6 min-height">
    <v-app-bar
      elevation="0"
      @input="scrollUp"
    >
      <v-icon
        v-if="step > 1"
        class="icon-back"
        color="black"
        size="30"
        @click="step!==1 && step--"
      >
        {{ icons.mdiChevronLeft }}
      </v-icon>
      <v-app-bar-title class="d-flex justify-center w-100">
        <v-img
          max-height="130"
          max-width="130"
          src="@/assets/images/marketa_logos-05.png"
          class="mt-3"
        ></v-img>
      </v-app-bar-title>
    </v-app-bar>
    <v-main class="v-main min-height background-primary">
      <v-container class="mt-15">
        <v-stepper
          v-model="step"
          class="border-none"
          :style="{ 'background-color': '#f0f2f5' }"
        >
          <v-stepper-header
            class="d-flex justify-center"
            width="50%"
          >
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              PERIOD
            </v-stepper-step>

            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              CONTACT INFO
            </v-stepper-step>

            <v-stepper-step step="3">
              CHECKOUT
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="center mb-10">
                <span class="hh-bold title-checkout">{{ domainLink }}</span>
                <span class="title-checkout"> will soon be yours</span>
                <p class="sub-title-checkout">
                  Choose a longer registration period to save money over time
                </p>
              </div>

              <div class="content mx-auto">
                <v-row class="mb-7">
                  <v-col :cols="isMobile ? '' : 7">
                    <v-card
                      class="rounded-lg px-8"
                      tile
                    >
                      <v-radio-group
                        v-model="period"
                        class="pt-0"
                      >
                        <template v-slot:label>
                          <div class="my-3 title-card black--text">
                            Registration Period
                          </div>
                        </template>
                        <v-divider></v-divider>

                        <v-radio
                          value="1"
                          class="my-4"
                        >
                          <template v-slot:label>
                            <div>
                              <span class="font-weight-bold black--text">1 Years</span>&nbsp;&nbsp;&#8212;&nbsp;&nbsp;<span class="black--text">US$15.95/year</span>
                            </div>
                          </template>
                        </v-radio>
                        <v-divider></v-divider>
                        <v-radio
                          value="2"
                          class="my-4"
                        >
                          <template v-slot:label>
                            <div class="d-flex justify-space-between w-100">
                              <div>
                                <span class="font-weight-bold black--text">2 Years</span>&nbsp;&nbsp;&#8212;&nbsp;&nbsp;<span class="black--text">US$14.95/year</span>
                              </div>
                              <div>
                                <span class="green-text font-weight-bold text-body-2">SAVE 6%</span>
                              </div>
                            </div>
                          </template>
                        </v-radio>
                        <v-divider></v-divider>
                        <v-radio
                          value="3"
                          class="my-4"
                        >
                          <template v-slot:label>
                            <div class="d-flex justify-space-between w-100">
                              <div>
                                <span class="font-weight-bold black--text">3 Years</span>&nbsp;&nbsp;&#8212;&nbsp;&nbsp;<span class="black--text">US$13.95/year</span>
                              </div>
                              <div>
                                <span class="green-text font-weight-bold text-body-2">SAVE 12%</span>
                              </div>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-card>
                  </v-col>

                  <v-col :cols="isMobile ? '' : 5">
                    <v-card
                      class="rounded-lg px-8"
                      tile
                    >
                      <div class="my-5 title-card black--text">
                        Summary
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex justify-space-between grey-text my-4">
                        <span>2 Years(US$15.95/yr)</span><span class="black--text">US$29.90</span>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex justify-space-between text-subtitle-1 my-4">
                        <span class="font-weight-bold grey-text">Total</span><span class="font-weight-bold black--text">US$29.90</span>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex justify-center my-4">
                        <v-img
                          max-height="15"
                          max-width="15"
                          src="@/assets/images/Safe_Secure.svg"
                          class="me-2 my-auto"
                        ></v-img>
                        <span class="black--text">Safe & secure payment</span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <div class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    @click="step = 2"
                  >
                    Continue <v-icon dark>
                      {{ icons.mdiChevronRight }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="center mb-10">
                <span class="hh-bold title-checkout">{{ domainLink }}</span>
                <span class="title-checkout"> will soon be yours</span>
                <p class="sub-title-checkout">
                  Choose a longer registration period to save money over time
                </p>
              </div>

              <div class="content mx-auto">
                <v-row class="mb-7">
                  <v-col :cols="isMobile ? '' : 7">
                    <v-card
                      class="rounded-lg px-8"
                      tile
                    >
                      <div class="my-5 title-card black--text">
                        Contact info
                      </div>
                      <v-divider></v-divider>
                      <v-row class="mt-5">
                        <v-col>
                          <v-text-field
                            label="Email"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="6">
                          <v-text-field
                            label="First name"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Last name"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="6">
                          <v-text-field
                            label="Phone"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Company (Optional)"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="6">
                          <v-text-field
                            label="Street"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Country"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="6">
                          <v-text-field
                            label="City"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Postal code"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col :cols="isMobile ? '' : 5">
                    <v-card
                      class="rounded-lg px-8"
                      max-height="250"
                      tile
                    >
                      <div class="my-5 title-card black--text">
                        Summary
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex justify-space-between grey-text my-4">
                        <span>2 Years(US$15.95/yr)</span><span class="black--text">US$29.90</span>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex justify-space-between text-subtitle-1 my-4">
                        <span class="font-weight-bold grey-text">Total</span><span class="font-weight-bold black--text">US$29.90</span>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex justify-center my-4">
                        <v-img
                          max-height="15"
                          max-width="15"
                          src="@/assets/images/Safe_Secure.svg"
                          class="me-2 my-auto"
                        ></v-img>
                        <span class="black--text">Safe & secure payment</span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <div class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    @click="step = 3"
                  >
                    Continue <v-icon dark>
                      {{ icons.mdiChevronRight }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="center mb-10">
                <span :class="['hh-bold', 'title-checkout']">{{ domainLink }}</span>
                <span class="title-checkout"> will soon be yours</span>
                <p class="sub-title-checkout">
                  Choose a longer registration period to save money over time
                </p>
              </div>

              <div class="content mx-auto">
                <v-row class="mb-7">
                  <v-col :cols="isMobile ? '' : 7">
                    <v-card
                      class="rounded-lg px-8"
                      tile
                    >
                      <div class="my-5 title-card black--text">
                        Payment method
                      </div>
                      <v-divider></v-divider>
                      <v-row class="mt-5">
                        <v-col>
                          <v-text-field
                            label="Card number"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col>
                          <v-text-field
                            label="Expiration"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            label="CVV"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col>
                          <v-text-field
                            label="First name"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            label="Last name"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col :cols="isMobile ? '' : 5">
                    <v-card
                      class="rounded-lg px-8"
                      :max-height="promoCode ? 380 : 300"
                      tile
                    >
                      <div class="d-flex justify-space-between flex-row my-5">
                        <div class="title-card black--text">
                          Summary
                        </div>
                        <span
                          v-if="!promoCode"
                          class="primary-color text-subtitle-2 cursor-pointer"
                          @click="promoCode = true"
                        >Enter promo code</span>
                        <span
                          v-else="promoCode"
                          class="primary-color text-subtitle-2 cursor-pointer"
                          @click="promoCode = false"
                        >Cancel</span>
                      </div>
                      <v-divider></v-divider>

                      <div
                        v-if="promoCode"
                        class="d-flex justify-space-between grey-text mt-6"
                      >
                        <div class="mt-1 d-flex justify-space-between w-100">
                          <v-text-field
                            label="Enter promo code"
                            outlined
                            dense
                            class="me-4"
                          ></v-text-field>
                          <v-btn
                            class="btn-apply-promo"
                            outlined
                          >
                            Apply
                          </v-btn>
                        </div>
                      </div>

                      <div :class="['d-flex', 'justify-space-between', 'grey-text', promoCode ? 'mb-4' : 'my-4']">
                        <span>2 Years(US$15.95/yr)</span><span class="black--text">US$29.90</span>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex justify-space-between text-subtitle-1 my-4">
                        <span class="font-weight-bold grey-text">Total</span><span class="font-weight-bold black--text">US$29.90</span>
                      </div>
                      <v-divider></v-divider>
                      <v-btn
                        color="primary"
                        block
                        class="mt-5"
                        @click="step = 3"
                      >
                        Submit Purchase
                      </v-btn>
                      <div class="d-flex justify-center my-4">
                        <v-img
                          max-height="15"
                          max-width="15"
                          src="@/assets/images/Safe_Secure.svg"
                          class="me-2 my-auto"
                        ></v-img>
                        <span class="black--text">Safe & secure payment</span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js'
import useAppConfig from '@/@core/@app-config/useAppConfig'
import CardContactInfo from '@/views/LandingPage/components/card/Card-ContactInfo.vue'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: { mdiChevronRight, mdiChevronLeft },
      isDark,
    }
  },
  components: {
    CardContactInfo,
  },
  data() {
    return {
      // domainLink: 'happyhome.com',
      step: 1,
      promoCode: false,
      period: 1,
    }
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        default:
          return false
      }
    },
    domainLink() {
      return this.$route.params.domain
    },
  },
  methods: {
    scrollUp() {
      window.scrollTo(0, 0)
    },
  },
}
</script>
<style scoped>
.min-height {
  min-height: 95vh;
}
.border-none {
  border: 0px !important;
}
.icon-back {
  position: absolute;
  top: 33%;
  left: 20px;
  cursor: pointer;
}
.background-primary {
  background-color: #f0f2f5;
}
.center {
  text-align: center;
}
.hh-bold {
  font-weight: 800;
}
.title-checkout {
  color: #000;
  font-size: 28px;
}
.sub-title-checkout {
  font-size: 14px;
  color: #000;
  margin-top: 10px;
}
.content {
  width: 90%;
}
.title-card {
  color: #000;
  font-weight: 700;
  font-size: medium;
}
.w-100 {
  width: 100%;
}
.green-text {
  color: #4caf50;
}
.grey-text {
  color: #9e9e9e;
}
.primary-color {
  color: #1f979e;
}
.cursor-pointer {
  cursor: pointer;
}
.btn-apply-promo {
  background-color: '#fff';
  color: #1f979e;
}
/* .v-main {
    background-color: "green";
} */
</style>
